@import url(https://fonts.googleapis.com/css2?family=Audiowide&family=Comfortaa&family=Lato&family=Poiret+One&family=Space+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #161618;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(243, 243, 243);
}

a:link {
  text-decoration: none;
  color: #f3f5f0;
}

a:visited {
  text-decoration: none;
  color: #f3f5f0;
}

h1 {
  font-family: 'Space Mono', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 0.5em;
  letter-spacing: 0.15em;
  margin-bottom: 5vh;
}

p {
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 1.25rem;
  font-weight: 100;
  letter-spacing: 0.11em;
}

.icon {
  margin: 0.6rem 1rem 0rem 0rem;
}

.arrow {
  margin: 0.6rem 0rem 0rem 1rem;
}

.underline {
  width: 60vw;
  height: 1rem;
  border-top-width: 1px;
  border-top-style: solid;
  margin-bottom: 5vh;
  border-color: rgb(168, 60, 27);
}

.App-link {
  color: #275a2a;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

